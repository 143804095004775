"use client";

import { LoadingSpinner } from "@faire/web--source/slate/LoadingSpinner";
import { useMaybeDomainStripping } from "@faire/web--source/ui/hooks/useMaybeDomainStripping";
import React from "react";
import styled, { useTheme } from "styled-components";

import { useShouldShowSpinner } from "../../ui/hooks/useShouldShowSpinner";
import { themeOrDefault } from "../Theme";
import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "../utils/highVisSlateComponent";

import { ButtonStyles } from "./styles";
import { buttonTheme } from "./theme";
import { ButtonProps, StyledButtonProps } from "./types";
import { useGetAttributes } from "./utils";

/**
 * @deprecated use `Button` from `@faire/slate/core/button` - since 2025-02-28
 *
 * @trackcomponent
 *
 * @link [Docs](https://slate.faire.team/slate/component/button)
 */
export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const theme = useTheme();

  const { isDisabled, handleClick, shouldShowSpinner } = useShouldShowSpinner(
    props.disabled || false,
    props.loading || false,
    props.onClick
  );

  const { potentiallyRelativeLink = props.linkTo } = useMaybeDomainStripping(
    props.linkTo,
    "target" in props ? props.target : undefined
  );

  const childNode = shouldShowSpinner ? (
    <HiddenChildrenWrapper>{children}</HiddenChildrenWrapper>
  ) : (
    children
  );

  const { as, linkProps } = useGetAttributes({
    ...props,
    linkTo: potentiallyRelativeLink,
  });

  const {
    buttonRef,
    linkTo,
    tertiaryColor,
    destructive,
    loading,
    variant,
    small,
    width,
    minWidth,
    ...rest
  } = props;

  return (
    <StyledButton
      {...rest}
      {...linkProps}
      ref={buttonRef as React.Ref<any>}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      as={as}
      onClick={handleClick}
      $tertiaryColor={tertiaryColor}
      $small={small}
      theme={theme}
      $loading={loading}
      $destructive={destructive}
      $variant={variant}
      $width={width}
      $minWidth={minWidth}
    >
      {childNode}
      {shouldShowSpinner && (
        <StyledLoadingSpinner
          size="medium"
          destructive={destructive}
          variant={props.variant}
        />
      )}
    </StyledButton>
  );
};
Button.displayName = "Button";

const HiddenChildrenWrapper = styled.span`
  visibility: hidden;
  cursor: inherit;
  color: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button<StyledButtonProps>`
  ${ButtonStyles}
  ${highVisSlateComponent(HIGH_VIS_FILTER.COMPONENT)}
`;

type StyledLoadingSpinnerProps = Pick<ButtonProps, "variant" | "destructive">;
const StyledLoadingSpinner = styled(
  LoadingSpinner
).attrs<StyledLoadingSpinnerProps>((props) => {
  const { theme, variant = "primary" } = props;
  const buttons2Theme = themeOrDefault(theme).buttons2 ?? buttonTheme;
  const buttons2VariantTheme = buttons2Theme[variant].loading;
  const { color } =
    typeof buttons2VariantTheme === "function"
      ? buttons2VariantTheme({
          $destructive: props.destructive,
          $variant: variant,
        })
      : buttons2VariantTheme;

  return { color };
})<StyledLoadingSpinnerProps>`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
`;
